<template>
  <b-card>

    <!-- BODY -->
    <template v-if="alert">
      <b-alert
        :variant="alertDetail.variant"
        class="mb-1"
        show
      >
        <h4 class="alert-heading">
          {{ alertDetail.title }}
        </h4>
        <div
          v-if="alertDetail.variant === 'danger'"
          class="alert-body"
        >
          <ul class="pl-2">
            <li
              v-for="(item, index) in alertDetail.text"
              :key="index"
            >
              <template v-for="txt in item">
                {{ txt }}
              </template>
            </li>
          </ul>
        </div>
        <div
          v-else
          class="alert-body"
        >
          {{ alertDetail.text }}
        </div>
      </b-alert>
    </template>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="previewFile"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="updatePreview"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG or PNG. Max size of 1MB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(updateUser)"
      >
        <b-row>
          <b-col sm="6">
            <validation-provider
              #default="validationContext"
              name="Username"
              rules="required"
            >
              <b-form-group
                label="Username"
                label-for="account-username"
              >
                <b-form-input
                  v-model="generalData.username"
                  placeholder="Username"
                  name="username"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
            >
              <b-form-group
                label="Full Name"
                label-for="fullname"
              >
                <b-form-input
                  v-model="generalData.fullname"
                  name="fullname"
                  placeholder="Full Name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="validationContext"
              name="NIK"
              rules="required"
            >
              <b-form-group
                label="NIK"
                label-for="nik"
              >
                <b-form-input
                  v-model="generalData.nik"
                  name="nik"
                  placeholder="NIK"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="email"
            >
              <b-form-input
                v-model="generalData.email"
                name="email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Phone Number"
              label-for="phonenumber"
            >
              <b-form-input
                v-model="generalData.phonenumber"
                name="phonenumber"
                placeholder="Phonenumber"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Unit Kerja"
              label-for="unit"
            >
              <b-form-input
                v-model="generalData.unit"
                name="unit"
                placeholder="Unit"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- bio -->
          <b-col cols="12">
            <b-form-group
              label="Signature"
              label-for="bio-area"
            >
              <quill-editor
                id="signature"
                ref="signature"
                v-model="generalData.signature"
                class="editor"
                :options="editorOption"
              />
            </b-form-group>
          </b-col>
          <!--/ bio -->

          <!--
          <b-col
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="warning"
              class="mb-50"
            >
              <h4 class="alert-heading">
                Your email is not confirmed. Please check your inbox.
              </h4>
              <div class="alert-body">
                <b-link class="alert-link">
                  Resend confirmation
                </b-link>
              </div>
            </b-alert>
          </b-col>
          -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
            >
              Save changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText,
  BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      profileFile: null,
      previewFile: (this.generalData.image) ? this.generalData.image : null,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean', 'link'],
          ],
        },
      },
    }
  },
  methods: {
    updatePreview(e) {
      this.previewFile = URL.createObjectURL(e)
    },
    updateUser() {
      console.log('here')
      document.getElementById('loading-bg').style.display = 'block'

      const formFile = new FormData()
      formFile.append('user_id', this.generalData.id)
      formFile.append('fullname', this.generalData.fullname)
      formFile.append('username', this.generalData.username)
      formFile.append('nik', this.generalData.nik)

      if (this.generalData.email) {
        formFile.append('email', this.generalData.email)
      }
      if (this.generalData.phonenumber) {
        formFile.append('phonenumber', this.generalData.phonenumber)
      }
      if (this.generalData.signature) {
        formFile.append('signature', this.generalData.signature)
      }
      if (this.profileFile) {
        formFile.append('image', this.profileFile)
      }
      this.$http.post('/profile', formFile, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .then(resp => {
          if (resp.status === 200 && resp.data.success === true) {
            document.getElementById('loading-bg').style.display = 'none'
            localStorage.setItem('userData', JSON.stringify(resp.data.data))
            this.previewFile = resp.data.image_url

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            this.alert = false
          }
        })
    },
  },
  setup() {
    const toast = useToast()
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      toast,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
